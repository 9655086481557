<!--<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo">
                    <img src="assets/img/black-logo.png" alt="image">
                </a>
                <h1>We are launching soon</h1>
                     <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
                </div>
                <form class="newsletter-form">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <span class="label-title"><i class='bx bx-envelope'></i></span>
                    </div>
                    <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Subscribe</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    <p>If you would like to be notified when our app is live, Please subscribe to our mailing list.</p>
                </form>
            </div>
        </div>
    </div>
</div>-->
<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="">Home</a></li>
                <li><a href="">Courses</a></li>
                <li></li>
            </ul>
            <h2>Thermal Engineering</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>M.E Thermal Engineering</h2>
                        <p>Mechanical Department</p>
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a routerLink="">Mechanical</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Students Placed</span>
                                <a routerLink="">454</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="">09/22/2023</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                        </div>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Join Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses8.jpg" alt="image">
                </div>

                <div class="courses-details-desc">
                    <!-- <3>What you'll learn</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Become an expert in Statistics
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Boost your resume with skills
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Gather, organize, data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use data for improved
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Present information KPIs
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Perform quantitative
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Analyze current data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Discover how to find trends
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Understand the fundamentals
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use SQL to create, design
                                </span>
                            </li>
                        </ul>
                    </div> -->
                    
                    <h3>About the Department</h3>
                    
                    <p>“Thermal Engineering is one of the discipline of Mechanical Engineering that involves the study of controlling heating and cooling processes in an enclosed or open environment using various equipments.”
                    </p>
                    <p>The Post Graduate Course in Thermal Engineering was started in the year 2011. Thermal Engineering is the domain of Mechanical Engineering with a specialization in the field of Thermal Process and Thermodynamics related aspects. The principles of Thermal Engineering are vital in the operation of vehicles and other machines. Thermal Engineering is a broad field of Engineering that encompasses technologies dealing with heating and cooling systems, transfer of heat, and fluid mechanics.</p>
                    
                    <h3>Faculty</h3>
                    <ul class="requirements-list">
                        <p>The teaching faculty in the department are well trained, highly qualified and experienced in
                            the subject matter. Our faculty are fellow members of renowned technical bodies. The
                            faculty members are well versed in teaching the concepts to students practically and they take
                            personal care and provide attention to every student concerning their strengths and weakness.
                            The student-teacher ratio is 1:11, which makes it an effective method of teaching and
                            learning.</p>
                        
                    </ul>
                    
              <h3>Laboratory Facilities</h3>
                   
                    <ul class="description-features-list">
                        <li>Thermal Engineering Laboratory</li>
                        <li>Thermal Systems Simulation Laboratory                     </li>
                       
                        
                    </ul>
                    
                    <h3>Association Activities</h3>
                    <ul class="audience-list">
                       <p>The Association has been a forum for students to successfully group together in order to discuss, debate and share dialogue, enabling them to create knowledge, technologies and ideas. It has motivated, inspired and encouraged students to put forth their best efforts.</p>
                       <p>M.E Thermal Engineering students association is regularly conducting various workshops, seminars, conference, etc. to cope up the students with the latest technological advancements. Frequently, experts from various fields are invited to the department for sharing their experience and knowledge with the students. The students are encouraged and guided to present technical papers in the conferences conducted by the other colleges</p>
                    </ul>
                   <!-- <h3>Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>James Anderson</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on Ednuv</a>
                                    </div>
                                </div>
                            </div>
                            <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div>-->
                </div>
<!-- <div class="related-courses">
                    <h3>Related Courses</h3>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

            <div class="col-lg-4">
                <!-- <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            84
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            4 Years
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div>-->

                <div class="courses-sidebar-syllabus">
                    <h3>Department Activities</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Student Achievement</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Staff Publication</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                   
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                  
                </div>

                <div class="courses-purchase-info">
                    <h4>Interested in this course ?</h4>
                    
                    <a routerLink="/contact" class="d-inline-block">Enquiry now</a>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Checkout</li>
            </ul>
            <h2>Academic Calendar</h2>
        </div>
    </div>
</div>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Academic Schedule for Non-Autonomous Affiliated Colleges <a >July 2023 - November 2023 (Odd Semester - Except Semester III)</a></span>
        </div>

        <form>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">UG / PG PROGRAM</h3>

                        <!--<div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control">
                                            <option>United Arab Emirates</option>
                                            <option>China</option>
                                            <option>United Kingdom</option>
                                            <option>Germany</option>
                                            <option>France</option>
                                            <option>Japan</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                       

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S.NO</th>
                                        <th scope="col">PROGRAMME</th>
                                        <th scope="col">SEMESTER</th>
                                        <th scope="col">COMMENCEMENT OF CLASSES</th>
                                        <th scope="col">LAST WORKING DATE</th>
                                        <th scope="col">COMMENCEMENT OF PRATICALS</th>
                                        <th scope="col">COMMENCEMENT OF END SEMESTER</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">B.E. / B.Tech. (Full Time)</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">V, VII</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">27/07/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">17/11/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">20/11/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">29/11/2023</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/single-products">M.B.A</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">V, VII, XI</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">27/07/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">17/11/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">20/11/2023</span>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">29/11/2023</span>
                                        </td>
                                    </tr>
                                   
                                    
                                    
                                   
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <label for="direct-bank-transfer">Note</label>

                                    1. The Theory and Practical Examination Schedules will be published in due Course (Practical Examinations will be conducted before the theory examinations.) <br>
                                    2. If necessary, loss of classes due to various curricular / co-curicular activities of the department / college may be compensated by conducting classes on Saturdays.
                                </p>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
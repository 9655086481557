<div class="feedback-slides owl-carousel owl-theme">
    <div class="single-feedback-item">
        <p>“A nation will be known by its people and the people will be known by their education and their culture.”</p>

        <div class="info">
            <h3>Dr.Ln.M.Stalin Arockiaraj, B.E., M.S. </h3>
            <span>Chairman</span>
            <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image">
        </div>
    </div>

    <div class="single-feedback-item">
        <p>“The difference between College and life? In College, you're taught a lesson and then given a test. In life, you're given a test that teaches you a lesson.”</p>

        <div class="info">
            <h3>Dr.J.Bridget Nirmala, M.E., Ph.D.</h3>
            <span>Chief Executive Officer</span>
            <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image">
        </div>
    </div>

    <div class="single-feedback-item">
        <p>“Fatima Michael is an unparallel experience for life. We delightfully welcome you in to the Michaelites family.”</p>

        <div class="info">
            <h3>Ln.Mrs.M.Fatima Mary</h3>
            <span>Secretary </span>
            <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
        </div>
    </div>
</div>

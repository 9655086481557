<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg3 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events</h2>
        </div>
    </div>
</div>

<section class="events-area pt-100 pb-70">
    <div class="container">
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg1">
                        <img src="assets/img/events/events1.jpg" alt="image">
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/single-events">Webinar on Image Processing </a></h3>
                        <p>By Dr. P. Karpagavalli, Associate Professor, K.L.N College of Engineering, Madurai</p>
                        <span class="location"><i class='bx bx-map'></i> Conduct by ECE Department</span>
                        <a routerLink="/single-events" class="join-now-btn">Join Now</a>
                    </div>
                </div>

                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>01</span>
                                <h3>24 August</h3>
                                
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  

    </div>
</section>
<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Admission</li>
            </ul>
            <h2>Admission</h2>
        </div>
    </div>
</div>
<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Under Graduate Courses Offered</span>
            
        </div>

        <form>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">COURSES</h3>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                      <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S.NO</th>
                                        <th scope="col">UG PROGRAMME</th>
                                        <th scope="col">Intake</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-total">
                                            <a routerLink="/">B.E. Automobile Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">30</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Civil Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >3.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Computer Science and Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >4.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Electrical and Electronics Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >5.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Electronics and Communication Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >6.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Mechanical Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">30</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >7.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Computer Science and Engineering (Artificial Intelligence & Machine Learning*)</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >8.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">B.E. Computer Science and Engineering (Cyber Security*)</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                    </tr>
                                    
                                    
                                   
                                </tbody>
                            </table>
                        </div>

                  
                    </div>
                </div>
            </div>
            <div id="particles-js-circle-bubble-3"></div>
        </form>
    </div>
</section>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Post Graduate Courses Offered</span>
            
        </div>

        <form>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">COURSES</h3>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                      <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S.NO</th>
                                        <th scope="col">PG PROGRAMME</th>
                                        <th scope="col">Intake</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a >1.</a>
                                        </td>
                                        <td class="product-total">
                                            <a routerLink="/">M.E. Thermal Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >2.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.E. Embeded System Technologies</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >3.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.E. Computer Science and Engineering</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">18</span>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a >4.</a>
                                        </td>
                                        <td class="product-name">
                                            <a routerLink="/">M.B.A</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">60</span>
                                        </td>
                                        
                                    </tr>
                                  
                                </tbody>
                            </table>
                        </div>
                        <div id="particles-js-circle-bubble-3"></div>
                  
                    </div>
                </div>
            </div>
            <div id="particles-js-circle-bubble-3"></div>
        </form>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Message for any Query</h2>
            <p></p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
   
</section>
<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:info@stmichaelgroup.org">info@stmichaelgroup.org</a></p>
                    <p><a href="mailto:info@stmichaelgroup.org">fmcetoffice@gmail.com</a></p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Admission Enquiry</h3>
                    <p><a href="tel:91 98421 44567">+91 99429 82311</a></p>
                    <p><a href="tel:91 98421 44567">+91 99429 81321</a></p>
                    <p><a href="tel:91 98421 44567">+91 99429 81322</a></p>
                    <h3>Kerala Admission Enquiry</h3>
                    <p><a href="tel:91 98421 44567">+91 97457 11167</a></p>
                    <p><a href="tel:91 98421 44567">+91 75589 41111</a></p>
                    </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://goo.gl/maps/vpK16J7zFHHrzPJR8" target="_blank">Madurai Sivagangai Main Road, <br>(15 Kms from Madurai)<br>
                        Madurai - 625 020.
                        Tamil Nadu.
                        India.</a></p>
                </div>
            </div>

        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>

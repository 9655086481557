<section class="mission-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-left">
                <span class="sub-title">Discover Mission</span>
                <h5>Why Choose</h5><h2> Fatima Michael?</h2>
            </div>

            <div class="mission-slides owl-carousel owl-theme">
                <div>
                    <h3>Quality can be better than quantity in this platform</h3>
                    <p>Advanced teaching learning methods through Hi Tech Classes.</p>
                    <p>Well-developed curriculum to go beyond text books</p>
                    <p>Teach less and Learn more concept.</p>
                    <a routerLink="/about-style-2" class="default-btn"><i class='bx bx-user-pin icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-user-pin icon-arrow after"></i></a>
                </div>

                <div>
                    <h3>A place where you can achieve</h3>
                    <p>Activity based subject approach.</p>
                    <p>Classroom experience is directed to be a good citizen with a sense of equality and compassion towards others.</p>
                    <p>	We strive to achieve the best academic standards in the world and your child will get the finest environment through our dedicated faculties.</p>
                    <a routerLink="/about-style-2" class="default-btn"><i class='bx bx-user-pin icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-user-pin icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

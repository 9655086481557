<div class="banner-section">
    
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                    <span class="sub-title"><h1>அறம் செய்ய விரும்பு !</h1></span>
                    
                 
                    <div class="btn-box">
                       
                        <a href="https://youtu.be/_X1iOKvuJ0w" class="video-btn popup-youtube"><span><i class='bx bx-play-circle'></i></span> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider"></div>
</div>
<app-header-style-one></app-header-style-one>
<div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="">Home</a></li>
                <li><a href="">Campus Life</a></li>
                <li></li>
            </ul>
            <h2>Campus Life</h2>
        </div>
    </div>
</div>

<!--<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop1.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Novel Bunch</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$455.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$455.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop2.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Chicks</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$541.50</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$541.50</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop3.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Divas</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$140.50</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$140.50</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop4.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Smart</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$547.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$547.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <a routerLink="/cart" class="default-btn"><i class='bx bx-cart icon-arrow before'></i><span class="label">Update Cart</span><i class="bx bx-cart icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <li>Subtotal <span>$1683.50</span></li>
                    <li>Shipping <span>$30.00</span></li>
                    <li>Total <span>$1713.50</span></li>
                </ul>
                <a routerLink="/checkout" class="default-btn"><i class='bx bx-shopping-bag icon-arrow before'></i><span class="label">Proceed to Checkout</span><i class="bx bx-shopping-bag icon-arrow after"></i></a>
            </div>
        </form>
    </div>
</section>-->

  
                    
<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Infrastructure </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Library</span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Internet Facility</span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Association Activities</span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Industrial Visits / In - Plant Training </span></a></li>
              
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                       
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about9.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Infrastructure</span>
                                    
                                    <p>Spreading across a sprawling area of 49 acres with a built-up area of 2,50,000 sq. ft. and open space for garden and sports facilities, FMCET campus is situated in a serene and pollution free environment. The campus has been designed by renowned architects specializing in educational architecture.</p>
                              </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about10.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Library</span>
                                 
                                    <p>Our college library has a built-up area of 10,000 sq.ft. The fully computerized library is well-equipped with modern facilities and a stock of 14, 000 Books (as per Anna University norms) and other resources in the form of Printed and Electronic Journals, CD-ROMs, Online Databases and Project Report etc. Open Access System is being followed to access the Books and Journals. All newspapers, magazines and journals are provided to the students, in the hostel to update their knowledge on the current affairs.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
                        
                  
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about11.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    
                                    <p>The campus gets connected to the World Wide Web through high speed 60 MBPS internet connectivity. The connection is distributed through the campus network to an internet connecting various laboratories and subnets inside each labs, departments, workshop, library and office. After college working hours (from 04:00 pm to 06:00 pm), Computer Laboratory is available for the hostel students to access study material from internet.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
 
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about12.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Association Activities</span>
                                   
                                    <p>Technical skills of the students are evaluated by various Association activities which are organized by each department. Association activities include Technical Quiz, Paper Presentations, Science and Technology Exhibitions and Project Contests. Along with, Crimson English Club which gives training in English speaking skills as well as Soft Skills, Personality Development Skills exclusively for second and third year students regularly.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                     
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about13.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Industrial Visits</span>
                                    
                                    <p>Our Students are taken for industrial visits to face future challenges of quality, competition, efficiency and excellence. In-Plant Training is provided an industrial exposure to the students as well as develops their career in the high tech industrial requirements. Our students have attended In-Plant Training in reputed companies in and around Madurai.</p>
                               </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-feedback-style-one></app-feedback-style-one>
<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Mentors </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Lions Club</span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Sports & Games</span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Auditorium </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Hostels </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Transports </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>NSS </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>RRC </span></a></li>
               
              
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                       
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about14.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Mentors</span>
                                    
                                    <p>Recently, Mentoring System has emerged as a strong response to the plight of students at-risk. We offer a highly-efficient Mentoring system through which a group of students consisting of 10-15 students are assigned to a faculty member at the commencement of the program. Mentors meet their students and guide them with their studies and extra-curricular activities. Mentors coordinate with the parents regarding the progress of the students as well. In necessary occasions the international youth trainers and counselors are invited for the betterment of students’ psychology.</p>
                              </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about15.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Lions Club</span>
                                 
                                    <p>Our Lions club encourages students to develop their leadership qualities by participating in social service activities like blood donation, charity etc. Our students have done various service projects through Lions Club as volunteers.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
                        
                  
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about16.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Sports & Games</span>
                                    <p>Sports help in building the values of leadership and team spirit among the students. It also inculcates the habit of winning in the students which is very important in today’s competitive world. It enhances physical and mental health and develops spirit of sportsmanship among students. Our campus encompasses state-of-the-art playing grounds for football, cricket, hand ball, kabaddi and volley ball and have facilities for indoor games like Carom and Chess. Our students are trained by Professional Trainers. Sports Day is organized by the College in second term of every academic year, which helps the students for their inclusive development. Our college students represent university level in various sports events.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
 
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about17.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Auditorium</span>
                                   
                                    <p>FMCET has a well-equipped Auditorium with a seating capacity of 1000 people which is often utilized for cultural activities and celebrations. Our fully centralized AC auditorium is used as a venue for department conferences and seminars. We have a Board Room which is Air-conditioned and well-furnished for the Management Meetings and FDPs of faculty members.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>
                
                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about18.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Hostels</span>
                                   
                                    <p>Our college provides separate hostel for boys and girls constructed in a calm and serene location. The hostel provides complete residential facilities to the students. The hostel rooms are spacious, adequately ventilated, well maintained and furnished with necessary accessories. Our hostels make students feel at home.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about19.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Transport</span>
                                   
                                    <p>Transportation is to facilitate easy travel for the students and staff residing outside the college campus. The buses travel through the city and suburbs; routes are planned properly to be punctual and convenient for students and staff. The busses are functioned from Madurai, Manamadurai, Kalayar kovil, Sivagangai and Melur. On special occasions, trips are arranged perfectly - for hostellers during holidays and for industrial visits and social service activities. A special transport schedule has been prepared and will be published in our college website during Anna University Examinations.</p>
                                    
                                   
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about20.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">NSS</span>
                                   
                                    <p>The main aim of National Service Scheme (NSS) is personality development through social service or community service. National Service Scheme is functioning very effectively in our college. Camps are being conducted to involve our students in social activities through NSS. The services carried out by our NSS students are as follows:</p>
                                    
                                    <ul class="description-features-list"> 
                                        <li>Adoption of Village</li>
                                        <li>Medical Camp</li>
                                        <li>Blood Donation Camp</li>
                                        <li>Eye Donation Awareness Camp</li>
                                        <li>Vaigai River Cleaning</li>
                                        <li>Rally</li>

                                </ul>
                                </div>
                            </div>
                        </div>


                        
  
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="row">
                     

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about21.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">YRC & RRC</span>
                                   
                                    <p>Youth Red Cross (YRC) & Red Ribbon Club (RRC) provides opportunities to the youth – the makers of the future, to contribute their values uplift our society. We offer YRC and RRC Programs to inculcate our students in humanitarian actions. We have organized the following Awareness Programmes:</p>
                                    
                                    <ul class="description-features-list"> 
                                        <li>National Voters’ Day</li>
                                        <li>Homeopathy Healthcare</li>
                                        <li>Covid – 19’ Corona Virus
                                        </li>
                                       

                                </ul>
                                </div>
                            </div>
                        </div>


                        
  
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events Details</h2>
        </div>
    </div>
</div>

<section class="events-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="events-details-header">
                    <a routerLink="/events" class="back-all-events"><i class='bx bx-chevrons-left'></i> Back To All Events</a>
                    <h3>Webinar on Image Processing</h3>
                    <ul class="events-info-meta d-none">
                        <li><i class="flaticon-timetable"></i> 24 August, 2023</li>
                        <li><i class="far fa-clock"></i> 10.00AM - 10.00PM</li>
                    </ul>
                    <div class="events-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a href="#">Image Processing</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Date</span>
                                10 August, 2023
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Time</span>
                                10.30AM - 11.30AM
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="events-details">
                    <div class="events-details-image">
                        <img src="assets/img/events/events1.jpg" alt="image">
                    </div>

                    <div class="events-details-desc">
                        <p>Image Processing is the use of a digital computer to process digital images through an algorthim. As a sub category or field of digital signal processing, digital image processing has many advantages over analog image processing. </p>
                       
                        <blockquote class="wp-block-quote">
                            <p>Image Processing</p>
                            <cite></cite>
                        </blockquote>
                        <p>It Allows a much wider range of algorthims to be apply to the input data and can avoid problems such as buildup of noise and distortion during processing. </p>
                        
                    </div>
                    
                
          
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_events_details">
                        <h3 class="widget-title">Details</h3>

                        <ul>
                            <li><span>Start:</span> August 24, 2023 10:30AM</li>
                            <li><span>End:</span> August 24, 2020 11:30AM</li>
                            <li><span>Events Category:</span> <a routerLink="/events">Image Processing</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_events_details">
                        <h3 class="widget-title">Organizer</h3>

                        <ul>
                            <li><span>Organizer Name:</span> <a routerLink="/single-events">Mr. Elayaraja, HoD, ECE</a></li>
                            <li><span>Phone:</span> <a href="tel:+1518 285679">97868 36887</a></li>
                            <li><span>Email:</span> <a href="mailto:hello@ednuv.com">hodece@fmcet.ac.in</a></li>
                            <li><span>Website:</span> <a href="#" target="_blank">http://www.fmcet.ac.in</a></li>
                        </ul>
                    </section>

                  

                   

                    <section class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">97868 36887</a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
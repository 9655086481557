<app-header-style-one></app-header-style-one>
<!--
<div class="page-title-area page-title-style-two item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses 3 Columns</h2>
        </div>
    </div>
</div>

<section class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select>
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Business</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                Free
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Design</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 15 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-price">
                                $250
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Development</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-price">
                                $150
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Language</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Liam King</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>

                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                <span>$200</span>
                                $195
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Management</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Lina D'Souza</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                $178
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Photography</a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                $500
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">2</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">3</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">4</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">5</a>
                    <a routerLink="/courses-3-columns-style-1" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->


<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="">Home</a></li>
                <li><a href="">Courses</a></li>
                <li></li>
            </ul>
            <h2>Mechanical Engineering</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>B.E Mechanical Engineering</h2>
                        <p>Electronics DEPARTMENT</p>
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a routerLink="">Mechanical</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Students Placed</span>
                                <a routerLink="">454</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="">09/22/2023</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                        </div>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Join Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses6.jpg" alt="image">
                </div>

                <div class="courses-details-desc">
              
                    
                    <h3>About the Department</h3>
                    
                    <p>Department of Mechanical Engineering, at Fatima Michael College of Engineering and Technology, was established along with the institute in 2009 with the intake of 60 students. Post graduate Programme in Thermal Engineering was started with the intake of 18 students during the academic year 2011-2012. At present, the department is affiliated to Anna University, Chennai and approved by AICTE, New Delhi. The department consistently maintains good academic records and produces quality Mechanical Engineers.     
                        The objective of the department is to produce high calibre technocrats and eminent Mechanical Engineers. The department has well developed laboratories and workshops to impart practical knowledge and skills to students.
                        </p>
                    <h3>Faculty</h3>
                    <ul class="requirements-list">
                        <p>The department functions with 11 teaching faculty and 5 supporting staff and is headed by Dr.K.Vinayagar M.E., Ph.D. The head of the department has more than 23 years of teaching experience and produced 2 Ph.D holders and presently guiding 3 scholars. Currently, the department has 4 Ph.D., holders and 2 are pursuing Ph.D. The average teaching experience of the faculty is more than ten years. The faculties are specialized in different areas of Mechanical Engineering such as production, design, manufacturing, industrial, thermal engineering, CAD/CAM and materials. They show keen interest in updating their knowledge by regularly attending SDP’s, workshops, STTPs, seminars, webinars and international conferences. They also actively participate in research and publish regularly in peer reviewed international journals and conferences. </p>>
                        
                    </ul>
                    
                    
                    <h3>Laboratory Facilities</h3>
                    <p>Department of Mechanical Engineering provides special attention on recent engineering innovations and advancements. The following laboratory facilities are available to fulfil the university curriculum and to pursue research work:
                    </p>
                    <ul class="description-features-list">
                        <li>Fully equipped Workshop</li>
                        <li>Fluid Mechanics and Machinery Laboratory
                        </li>
                        <li>Strength of Materials Lab

                        </li>
                        <li>Thermal Engineering Lab-I & II

                        </li>
                        <li>Communication System laboratory
                        </li>
                        <li>Dynamics Lab

                        </li>
                        <li>Metrology & Measurements Lab

                        </li>
                        <li>Mechatronics Lab

                        </li>
                        <li>CAD / CAM Lab

                        </li>
                    </ul>
                    
                    <h3>Association Activities</h3>
                    <ul class="audience-list">
                        <P>Students are taken for regular industrial visits to various industries in order to make them updated with latest trends of the industry sphere and to improve their practical skills. Students are trained in placement activities by professionals and industry experts. The department has an entrepreneur development cell that aims at creating successful leaders of tomorrow.
                        </P>
                        
                    </ul>
                   <!-- <h3>Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>James Anderson</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on Ednuv</a>
                                    </div>
                                </div>
                            </div>
                            <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div>-->
                </div>
<!-- <div class="related-courses">
                    <h3>Related Courses</h3>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

            <div class="col-lg-4">
                <!-- <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            84
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            4 Years
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div>-->

                <div class="courses-sidebar-syllabus">
                    <h3>Department Activities</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Student Achievement</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Staff Publication</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                   
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                  
                </div>

                <div class="courses-purchase-info">
                    <h4>Interested in this course ?</h4>
                    
                    <a routerLink="/contact" class="d-inline-block">Enquiry now</a>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Error</li>
            </ul>
            <h2>Error 404</h2>
        </div>
    </div>
</div>

<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>Oops!</h1>
            <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>
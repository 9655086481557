<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="">Home</a></li>
                <li><a href="">Courses</a></li>
                <li></li>
            </ul>
            <h2>Electrical Engineering</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>B.E Electrical & Electronics Engineering</h2>
                        <p>Electrical DEPARTMENT</p>
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a routerLink="">Electrical</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Students Placed</span>
                                <a routerLink="">454</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="">09/22/2023</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                        </div>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Join Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/courses5.jpg" alt="image">
                </div>

                <div class="courses-details-desc">
                  <!--  <h3>What you'll learn</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Become an expert in Statistics
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Boost your resume with skills
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Gather, organize, data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use data for improved
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Present information KPIs
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Perform quantitative
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Analyze current data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Discover how to find trends
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Understand the fundamentals
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use SQL to create, design
                                </span>
                            </li>
                        </ul>
                    </div>-->
                    
                    <h3>About the Department</h3>
                    
                    <p>The department of EEE was established in the year 2011 with an intake of 60 students
                        approved by AICTE. The department accommodate Outcome Based Education which help
                        the students to learn, develop and serve to the society. The department has experienced and
                        dedicated faculty with a wide range of specialization Power System, Power Electronics,
                        Embedded Systems. The department has well established laboratory facilities to conduct
                        research work on different specialized areas like Renewable Energy, Power Quality, Power
                        Electronics. The students of the department have received external research funding from
                        Tamil Nadu State Council for Science and Technology in recent years. The Students of the
                        departments are joined in reputed industries through placements and some of them are turned
                        to be an entrepreneur. The department has a good network of alumni.</p>
                    
                        <h3>Faculty</h3>
                        <ul class="requirements-list">
                            <p>Fully qualified, experienced and full-fledged faculty members are working in the 	department with a common goal to establish academic excellence. Each faculty keep 	abreast of their academic discipline through continuing education, research, and/or 	participation in the activities to strive for improving the effectiveness of their 	teaching, to take a sympathetic interest in the progress and development of each of 	their students and to keep accurate records of academic standing of each student in 	their classes. The Department has professors of diverse disciplines in their Ph.D and 	PG level. They include power systems engineering, high voltage engineering, drives 	and control, power electronics and drives, embedded systems etc. The faculty 	members have contributed for research contribution by the way of publishing books, presenting papers in international and national conferences and publishing research 	papers in referred journals.</p>
                           
                            
                        </ul>
                    
                    <h3>Laboratory Facilities</h3>
                    <P>The department of Electrical and Electronics Engineering offers a complete range of specialized laboratories with the latest equipment for teaching and research. Laboratories provide an environment for students to reinforce their understanding of the fundamental principles of Electrical and Electronics Engineering and apply their knowledge through hands-on learning with using current technology. The Department Laboratories are as follows</P>
                  
                    <ul class="description-features-list">
                        <li>Engineering Practices Lab                        </li>
                        <li>Electric Circuits Lab                       </li>
                        <li>Machines Lab                      </li>
                        <li>Control System Lab</li>
                        <li>Measurement and Instrumentation Lab                        </li>
                        <li>Power Electronics Lab                        </li>
                        <li>Power System Simulation Lab                        </li>
                        <li>Renewable Energy lab</li>
                      
                    </ul>
                    
                    <h3>Association Activities</h3>
                    <ul class="audience-list">
                        <P>The students are trained to develop their communication skills and soft skills. The
                            association actively conducts various inter department programs like Paper presentations,
                            Seminars and Workshops. Motivation programs are conducted to inculcate self-confidence
                            and to groom them as a confident individual. The faculty hones their skills by participating
                            and presenting papers in various International/National Level Seminars/Symposia/ Workshop
                            organized by technical societies like ISTE.</P>
                        
                    </ul>
                   <!-- <h3>Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>James Anderson</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on Ednuv</a>
                                    </div>
                                </div>
                            </div>
                            <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>

                        <div class="user-review">
                            <img src="assets/img/user3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div>-->
                </div>
<!-- <div class="related-courses">
                    <h3>Related Courses</h3>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>

                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>

                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

            <div class="col-lg-4">
                <!-- <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            84
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            4 Years
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div>-->

                <div class="courses-sidebar-syllabus">
                    <h3>Department Activities</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Student Achievement</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                 
                    <h3>Staff Publication</h3>
                    <h4>2021-2022</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                   
                    <h4>2022-2023</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                <a routerLink="/">
                                    <span class="number">1.</span> xxxx <span class="free-lesson">Free</span>
                                </a>
                            </li>
                           
                            <li>
                                <span class="number">2.</span> xxxx <span class="free-lesson">Free</span>
                            </li>
                            
                            
                        </ul>
                    </div>                  
                </div>

                <div class="courses-purchase-info">
                    <h4>Interested in this course ?</h4>
                    
                    <a routerLink="/contact" class="d-inline-block">Enquiry now</a>
                    
                </div>
            </div>
        </div>
    </div>
</section>

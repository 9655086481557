<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>

                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Madurai Sivagangai Main Road, <br>(15 Kms from Madurai),<br>
                                Madurai - 625 020.<br>
                                Tamil Nadu.<br>
                                India</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="+91 98421 44567">+91 99429 82311</a> <br>
                            <a href="+91 98421 44567">+91 99429 81321</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">info@stmichaelgroup.org</a>
                            <a href="mailto:hello@ednuv.com">principalfmcet@gmail.com</a>
                        </li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="https://www.facebook.com/FatimaMichaelEngineeringCollegeMadurai"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="https://instagram.com/fmcet_mdu?igshid=MzNlNGNkZWQ4Mg=="><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
       
      


            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <ul class="support-link">
                        
                        <li><a href="http://fmcet.in/eletter.php">e-News</a></li>
                    </ul>
                    <div class="newsletter-box">
                        <br><br>
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                        <ul class="useful-link">
                        <li><a href="https://stmichaelgroup.org">www.stmichaelgroup.org</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i>2020 <a routerLink="/" target="_blank">St.Michael Group of Institutions</a> <a href="https://stmichaelgroup.org/" target="_blank"></a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>

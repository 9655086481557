<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/#">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" class="shadow" alt="image">
                    <!--<img src="assets/img/about/about2.jpg" class="shadow" alt="image">-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 students.</h6>
                    <p>To learn new skills is all part of working the way up the ladder of professional development. In order to
                        motivate and teach this generation, our education system reinvented to be accountable for what
                        matters most. We mould students to develop their core competencies: Critical thinking and problem-
                        solving, agility and adaptability, initiative and entrepreneurialism, curiosity and imagination. Our
                        teaching, learning and assessing revolves in a new way.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Gaining a sense of accomplishment, and feeling proud of the work they have done, helps students feel
                            like they’re progressing in their work. An accomplishment feels like making a positive step forward for
                            oneself. Our institutions serve as a place for achievements.</p>
                    </div>
                </div>
            </div>
        </div>
        <section class="faq-area pt-100 pb-70">
            <div class="container">
                <div class="tab faq-accordion-tab">
                    <ul class="tabs d-flex flex-wrap justify-content-center">
                        <li><a href="#"><i class='bx bx-flag'></i> <span>Our Founder </span></a></li>
                        <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Chairman's Message</span></a></li>
                        <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Ceo's Message</span></a></li>
                        <li><a href="#"><i class='bx bx-book-open'></i> <span>Secretary's Message</span></a></li>
                        <li><a href="#"><i class='bx bx-info-circle'></i> <span>Principal Profile </span></a></li>
                    </ul>
        
                    <div class="tab-content">
                        <div class="tabs-item">
                            <div class="row">
                               
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-image">
                                            
                                           <img src="assets/img/about/about5.jpg" class="shadow" alt="image">
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">Founder</span>
                                            <h2>Learning is eternal and experience is valuable</h2>
                                            <h6>The bequest of the founder</h6>
                                            <p>Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA) was an altruistic and committed academician, responded to the challenges of improving the educational status especially in technical skills for the younger generations who were deprived of the benefits of professional education in the rural areas. With relentless efforts and foreseen big picture, our founder emphasized on excellence in education that will enhance the creative and innovative skills, physical and intellectual well-being of the students. Having crossed long years of hurdles and obstacles he reached the zenith of determination through St. Michael Educational and Charitable Trust offering value based quality education. He had engraved a milestone in the field of education in the soil of rural areas.</p>
                                            <p>Taking education to the marginalized classes of society across the state, especially in Sivagangai district and with an intuition to instill world class quality education, the man of stupendous efforts our founder has established "ST. MICHAEL EDUCATIONAL AND CHARITABLE TRUST" in the year 1993.</p>
                                           
                                           
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
        
                        <div class="tabs-item">
                            <div class="row">

                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-image">
                                            
                                           <img src="assets/img/about/about6.jpg" class="shadow" alt="image">
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">Chariman's Message</span>
                                            <h2>"Achieve your goals faster than you ever dreamed possible"</h2>
                                            <h6></h6>
                                            <p>Today most of the educational institutions are striving hard to achieve international standards with their world class infrastructural facilities. Fatima Michael College of Engineering & Technology is moving fastly ahead of others to satisfy these expectations in this competitive world and aims to bring out the latent potentials of young minds. With relentless efforts, the college aspires to orchestrate the students' potential for the enrichment and progress of society by equipping them with technical expertise and soft skills. Our institution instills the vision that will enable the students to become self motivated agents of social transformation. The students are exposed to a finest learning experience using theory, practical exercises, project assignments, case studies, industry expert interactions, debates and group discussions that multiplies the quality of inputs to the students and hones them to have practical perspective with strong technical expertise. While benchmarking with today's competitive world, our core competencies is titled to quality and innovation by effectively adapting technical skills to the ever emerging future and be the greatest differentiator in achieving their goals faster than even dreamed possible.</p>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                                
        
                               
                             
        
        
        
                              
                            </div>
                        </div>
        
                        <div class="tabs-item">
                            <div class="row">
                                
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-image">
                                            
                                           <img src="assets/img/about/about7.jpg" class="shadow" alt="image">
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">ceo's Message</span>
                                            <h2>"High aims from high characters and great objectives bring out great minds"</h2>
                                            <h6></h6>
                                            <p>Every educational institution needs to implement effective learning. Fatima Michael College of Engineering & Technology creates an everlasting image in the mind of students and inspires them to emulate what they have just been exposed to in the classroom. Certainly, our college will unveil the dimension to touch the professional excellence in the most memorable way. In our institution we deeply integrate the social responsibilities with the need of understanding of highly developed technologies and encourage ethical reasoning into learning agenda in a holistic manner. Better understanding of students especially about their motivation which kindles their interest, aptitude, engineering principles, concepts, values and knowledge to built student centric education, process and approaches for enhanced understanding are our great objectives to bring out great minds.</p>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                                
        
                           
        
        
        
                            </div>
                        </div>
        
                        <div class="tabs-item">
                            <div class="row">
                             
        
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-image">
                                            
                                           <img src="assets/img/about/about8.jpg" class="shadow" alt="image">
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">Secretary's  Message</span>
                                            <h2>"Thinking well is wise
                                                Planning well is wiser
                                                Doing well is the wisest"'s </h2>
                                            <h6></h6>
                                            <p>Fatima Michael College of Engineering & Technology has become a modern temple of learning where quality education is the very essence of its existence. The vision, motto and constructive activities could trigger setting up of this institution into a reputed University. Because just few years from its inception, the college has become a great source of academic strength in the present scenario for which I feel very proud. Our institution follows strategies to manage the closely interconnected corporate world that calls for better knowledge management. I believe that the courses offered in our institution is the wonderful beginning to a challenging set of new learning experience to prove individual's excellence in technical skills and soft skills. FMCET is the wisest choice for excellence in engineering education.</p>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                                
        
        
        
        
        
        
                            </div>
                        </div>
        
                        <div class="tabs-item">
                            <div class="row">
                             
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-image">
                                            
                                           <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                                        </div>
                                    </div>
                        
                                    <div class="col-lg-6 col-md-12">
                                        <div class="about-content">
                                            <span class="sub-title">Principal Profile</span>
                                            <h2> </h2>
                                            <h6></h6>
                                            <p>Dr. P. Nelson Raja, the prolific professional, has got 30 years of teaching experience in Engineering education. He started his career as a lecturer in RVS Engineering college at Dindigul and worked as the Head of the Mechanical Engineering Department and as the Vice Principal in Vellammal Engineering College at Madurai. Being an ISTE secretary, the man of thirst for knowledge has organized many faculty development programs. He has organized and attended many short term programmes sponsored by AICTE.</p>
                                            <p>He had been the coordinator for a week long Nuclear Festival sponsored by IGCAR, kalpakkam during 2009. He has published many National and International papers and guides the research scholars. He motivates students to organize and engage in various activities through Training and Placement cell and Entrepreneurs cell. He has organized many motivational programmes for the staff and students. With relentless efforts, he strives for the progress of the institution and guides the staff in accomplishing the vision of being a centre for technical and academic excellence. In all his activities he kindles the curiosity of the students to achieve more by their smart work. Each year our institution steps forward by securing two fold results than the previous semester.</p>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <!--  <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</section>

<app-our-mission></app-our-mission>

<section class="story-area ptb-100">
    <app-our-story></app-our-story>
</section>

<section class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</section>

<section class="values-area ptb-100">
    <app-our-values></app-our-values>
</section>

<!-- <section class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Management</h2>

        </div>

        <app-instructors-style-two></app-instructors-style-two>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>-->

<section class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>

        <app-partner-style-one></app-partner-style-one>
    </div>
</section>

<section class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>



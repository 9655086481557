<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-left">
                <h2>VISION</h2>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="story-content">
                
                <p>To be one of the leading and preferred engineering colleges in education, research and ethics, and achieve greater recognition for our efforts to make the world look to us for technology for future trends and innovations.
                </p>
                
            </div>
        </div>
    </div>
</div>

<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                
            </ul>
            <h4 >NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL</h4>
        </div>
    </div>
</div>
<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>National Assessment and Accreditation Council </span>
            
        </div>
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>About Naac </span></a></li>
                <li><a href="#"><i class='bx bx-flag'></i> <span>Accreditation </span></a></li>
              
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                       
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about22.png" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">About Naac</span>
                                
                                    <p>The NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL (NAAC) conducts assessment and accreditation of Higher Educational Institutions (HEI) such as colleges, universities or other recognised institutions to derive an understanding of the ‘Quality Status’ of the institution. NAAC evaluates the institutions for its conformance to the standards of quality in terms of its performance related to the educational processes and outcomes, curriculum coverage, teaching-learning processes, faculty, research, infrastructure, learning resources, organisation, governance, financial well being and student services.</p>
                              </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="tabs-item">
                    <div class="row">
                       
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    
                                   <img src="assets/img/about/about23.jpg" class="shadow" alt="image">
                                </div>
                            </div>
                
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <span class="sub-title">Accreditation</span>
                                
                                    <p>Accreditation in the act certifying an educational institution or program as meeting all official formal requirements of academic excellence, facilities, curriculum, etc.</p>
                                    <p>India has one of the largest and diverse education systems in the world. Privatization, widespread expansion, increased autonomy and introduction of programmes in new and emerging areas have improved access to higher education. At the same time, it has also led to widespread concern on the quality and relevance of the higher education.</p>
                                    <p>To address these concerns, the National Policy on Education (NPE) and the Programme of Action (POA) established an independent National Assessment and Accreditation Council (NAAC).</p>
                                    <p>Now we have been preparing all the details and we are going to submit SSR (Self Study Report) to NAAC, so that our college will be accredited soon.</p>
                              </div>
                            </div>
                        </div>


                    </div>
                </div>
              </div>
        </div>
    </div>
</section>
<!--<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/my-dashboard"><i class='bx bxs-dashboard'></i> Dashboard</a></li>
                <li><a routerLink="/orders"><i class='bx bx-cart'></i> Orders</a></li>
                <li><a routerLink="/downloads"><i class='bx bx-download'></i> Downloads</a></li>
                <li><a routerLink="/edit-address"><i class='bx bx-home-alt'></i> Addresses</a></li>
                <li><a routerLink="/edit-account" class="active"><i class='bx bx-edit'></i> Account Details</a></li>
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>

        <div class="myDashboard-content">
            <form class="edit-account">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>First name <span class="required">*</span></label>
                            <input type="text" class="form-control" value="James">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Last name <span class="required">*</span></label>
                            <input type="text" class="form-control" value="Anderson">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Display name <span class="required">*</span></label>
                            <input type="text" class="form-control" value="JamesET">
                            <span><em>This will be how your name will be displayed in the account section and in reviews</em></span>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Email address <span class="required">*</span></label>
                            <input type="email" class="form-control" value="hello@jamesanderson.com">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <legend>Password Change</legend>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Current password (leave blank to leave unchanged)</label>
                            <input type="password" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>New password (leave blank to leave unchanged)</label>
                            <input type="password" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Confirm new password</label>
                            <input type="password" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i class='bx bx-save icon-arrow before'></i><span class="label">Save Changes</span><i class="bx bx-save icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>-->
<header class="header-area p-relative">

    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call:
                            <a href="tel:+91 98421 44567">+91 99429 82311</a>
                        </li>
                    </ul>

                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="https://www.facebook.com/FatimaMichaelEngineeringCollegeMadurai"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="https://instagram.com/fmcet_mdu?igshid=MzNlNGNkZWQ4Mg=="><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                         <li><a routerLink="/contact"><i class='bx bx-log-in-circle'></i> Contact</a></li>
                         <li><a routerLink="/gallery"><i class='bx bx-log-in'></i> Gallery</a></li>
                        <li><a routerLink="/login"><i class='bx bx-log-in-circle'></i> Login</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area navbar-style-three">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <a class="navbar-brand" routerLink="/"><img src="assets/img/fmcetlogo.png" alt="logo"></a>
                            <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                 <img src="assets/img/fmcetlogo.png" alt="logo">
                        <img src="assets/img/black-logo.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="#" class="nav-link">Home</a>
                                <li class="nav-item" ><a class="navbar-brand" routerLink="/aboutus" >About Us</a>
                                    <li class="nav-item" ><a class="navbar-brand" routerLink="/admission" >Admission</a>

                            </li>

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Academics <i class='bx bx-chevron-down'></i></a>
                                
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/acadcal" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Academic Calendar</a></li>
                                    <li class="nav-item"><a href="#" class="nav-link">Under Graduate Courses<i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/auto" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Automobile Engineering</a></li>

                                            <li class="nav-item"><a routerLink="/civil" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Civil Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/cse" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Computer Science and Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/eee" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Electrical and Electronics Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/ece" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Electronics and Communication Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/mech" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Mechanical Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Computer Science and Engineering (Artificial Intelligence & Machine Learning*)</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">B.E. Computer Science and Engineering (Cyber Security*)</a></li>
                                            
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Post Graduate Courses <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/thermal" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">M.E. Thermal Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/embedded" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">M.E. Embedded System Technologies</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">M.E. Computer Science and Engineering</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Master of Bussiness Administration</a></li>
                                            
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Commitees <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Anti-Ragging Committee</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Internal Complaince Committee</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SC/ST Committee</a></li>
                                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grievance Redressal Committee</a></li>
                                            
                                        </ul>
                                    </li>
                                   <li class="nav-item"><a routerLink="/campuslife" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Campus LIfe</a></li>
                                     <!--<li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Library</a></li>-->
                                    <li class="nav-item"><a routerLink="/placement" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Placements</a></li>
                                    <!--<li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sports & Games</a></li>-->
                                </ul>
                            </li>

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">All Events</a></li>

                                   

                               </ul>
                            </li>
                      
                            <li class="nav-item"><a routerLink="/naac" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Naac</a></li>                         
                            
                          
                        </ul>
                        &nbsp;     &nbsp;&nbsp;&nbsp;&nbsp;     &nbsp;       
                        <img src="assets/img/cclogo.png"  alt="image">
                        
                      &nbsp;     &nbsp;&nbsp;&nbsp;&nbsp;     &nbsp;     &nbsp;&nbsp;&nbsp;&nbsp;    
                                    <img src="assets/img/logo.png"  alt="image">                          
                                
                       
                    </div>
                </nav>
            </div>
        </div>
    </div>

</header>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

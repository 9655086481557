<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-left">
                <h2>MISSION
                </h2>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                
                <p>To develop high quality technical education and technocrats with a sound grip on basic engineering principles, technical and managerial skills, innovative research capabilities, and exemplary professional conduct to lead and to use technology for the progress of humanity, adapting themselves to changing technological conditions with the highest ethical values.
                </p>
                
            </div>
        </div>
    </div>
</div>

<app-header-style-one></app-header-style-one>

<app-homethree-main-banner></app-homethree-main-banner>


<app-homethree-about></app-homethree-about>
<br><br>
<app-offer></app-offer>

<section class="partner-area pt-100">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</section>


<section class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>
<app-become-instructor-partner></app-become-instructor-partner>
